/**
 * Mode module
 */
 @include media-breakpoint-up(lg) {

    main:has(.split-view-wrapper) {
      padding-top: 0;
      padding-bottom: 0;
  
      > [class^="container"] {
        padding: 0;
      }
  
      .split-view-wrapper {
        display: flex;
        max-height: calc(100vh - var(--header-height));
        flex-grow: 1;
        overflow: hidden;
  
        > * {
          @include custom-scroll-bar();
          flex-grow: 1;
          overflow: auto;
          padding-top: 1.875rem;
          padding-bottom: 1.875rem;
        }
  
      }
  
    }
  
  }
  
  @include media-breakpoint-down(xl) {
  
    main:has(.split-view-wrapper) {
      padding: 0;
  
      .split-view-wrapper {
        padding: 1.5rem;
      }
  
    }
  
  }
  
  @include media-breakpoint-down(lg) {
  
    main:has(.split-view-wrapper) {
      padding-top: var(--header-height-mobile);
  
      .form-wrapper {
        @include custom-scroll-bar();
        background-color: $body-bg;
        overflow: auto;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: $zindex-fixed;
        padding: 1.5rem;
      }
  
    }
  
  }
  
  
  
  
  /*
   * Mode SPA
   */
  
  @include media-breakpoint-up(lg) {
  
    .body-wrapper.layout-split-view-vertical {
      max-height: 100vh;
  
      main {
        display: flex;
        flex-grow: 1;
        overflow: hidden;
        padding-top: 0;
        padding-bottom: 0;
  
        > * {
          @include custom-scroll-bar();
          flex-grow: 1;
          overflow: auto;
          padding-top: 2rem;
          padding-bottom: 2rem;
  
          &:last-child {
            border-left: 1px solid $border-color;
          }
  
        }
  
      }
  
    }
  
  }
  
  @include media-breakpoint-down(xl) {
  
    .body-wrapper.layout-split-view-vertical {
  
      main {
       padding: 0;
  
        > * {
          padding: 1.5rem;
        }
  
      }
  
    }
  
  }
  
  @include media-breakpoint-down(lg) {
  
    .body-wrapper.layout-split-view-vertical {
      padding-top: calc(var(--header-height-mobile) + 42px);
  
      .form-wrapper {
        @include custom-scroll-bar();
        background-color: $body-bg;
        overflow: auto;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: $zindex-fixed;
        padding: 1.5rem;
      }
  
    }
  
  }
  