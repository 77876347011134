.dt-table {
    table {
            tbody {
                tr 
                {
                    td:last-child 
                    { 
                        text-align: center !important;
                    } 
                } 
            } 

            thead {
                tr 
                {
                    th:last-child 
                    { 
                        text-align: center;
                    } 
                } 
            } 
        }
}

@media (max-width: 800px) {
    .dt-table tbody tr td {

        a span {
            display: none;
        }
        button span {
            display: none;
        }
    }
    
}
.info {
    margin-top: 8px;
    margin-bottom: -2px;
}