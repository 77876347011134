.body-wrapper.layout-public {

  main {
    padding: 0;
  }

  .container-narrow {
    width: 880px;
    max-width: 100%;
  }

  .part {
    padding: 4rem 0;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 2rem;

    &:after {
      display: block;
      content: "";
      width: 80px;
      height: 5px;
      background-color: $primary;
      margin-top: 1rem;
      border-radius: 3px;
    }

  }



  .part-contact {
    background-color: $secondary;
    color: $white;

    h2:after {
      background-color: $white;
    }

  }

}