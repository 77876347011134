/**
 * Bootstrap Variables
 */

// Colors

$blue: #00B7ED;
$green: #33CA7F;

$primary: $blue;
$secondary: #1A4079;

/**
 * Project variables
 */

$app-aside-logo-width: 70px;
$header-logo-width: 50px;
$header-logo-width-mobile: 40px !default;