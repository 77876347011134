.banner {
  background-image: linear-gradient(45deg, #141e30, #243b55);
  color: $white;
  padding: 4rem 0;
  position: relative;
  overflow: hidden;

  &:before {
    display: block;
    content: "";
    width: 30vw;
    height: 30vw;
    border: 1.75vw solid $primary;
    border-radius: 50%;
    position: absolute;
    bottom: 6rem;
    right: 6rem;
    transform: translateX(50%) translateY(50%);
    opacity: .2;
  }

  &:after {
    display: block;
    content: "";
    width: 4vw;
    height: 4vw;
    background-color: $primary;
    border-radius: 50%;
    position: absolute;
    bottom: 6rem;
    right: 6rem;
    transform: translateX(50%) translateY(50%);
    opacity: .2;
  }

  [class^="container"] {
    display: flex;
    align-items: center;
    gap: 4rem;
  }

  .illus {
    width: 35%;
    margin-left: 7%;
  }

  h1 {
    font-size: 2.25rem;
    font-weight: 500;

    strong {
      font-size: 1.5em;
      color: $primary;
      text-transform: uppercase;
      font-weight: 700;
    }

  }

}

/**
 * Responsive behaviour
 */

@include media-breakpoint-down(lg) {

  .banner {
    padding: 3rem 0;

    .illus {
      display: none;
    }

    h1 {
      font-size: 1.75rem;
    }

  }

}