.public-header {
  background-color: $white;
  padding: 1rem 0;
  position: relative;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: $zindex-fixed;
  box-shadow: $box-shadow;

  [class^="container"] {
    display: flex;
    align-items: center;
    gap: 3rem;
  }

  .logo {
    margin-bottom: -2.5rem;

    img {
      width: 110px;
    }

  }

  .btn-outline-primary {
    margin-left: auto;
    display: inline-flex;
    gap: .5rem;
    font-size: 0.9375rem;
    font-weight: 700;
  }

  ul {
    @include reset-list();
    display: flex;
    align-items: center;
    gap: 2rem;

    a {
      font-weight: 700;
      color: $body-color;
      font-size: 0.9375rem;

      &:hover {
        text-decoration: none;
        color: $secondary;
      }

    }

  }

}

/**
 * Responsive behaviour
 */

@include media-breakpoint-down(lg) {

  .public-header {

    .logo {
      margin-bottom: -2rem;

      img {
        width: 90px;
      }

    }

    ul {
      display: none;
    }

  }

}